import { useState } from "react";

import { submitMail } from "../../scripts/script";

const Joi = require("joi")

function Newsletter() {

    const [message, setMessage] = useState('');

    const validateData = (email: string) => {
      const schema = Joi.object({
          email: Joi.string().email({ tlds: { allow: false } }).required(),
      })
      
      const result = schema.validate({email: email})
      return result
  }
  
    const handleChange = (event: any) => {
      setMessage(event.target.value);
    };
  
    const haha = (val: string) => {
      const {error} = validateData(val)
      if (error) {
          setMessage("thats not an email >:(")
          return
      }
      submitMail(val)
      setMessage("mail sent :3")
    }

    return ( <div className="container" style={{margin: "2vw"}}>
        <h1 style={{margin: "0vw 0vw 1vw 0vw"}}>Want more?</h1>
      <h2 style={{margin: 0}}>SUB TO OUR NEWSLETTER</h2>
      <input
        type="text"
        id="message"
        name="message"
        onChange={handleChange}
        value={message}
      />
      <button style={{margin: 0}} onClick={() => {haha(message)}}>cumming soon :3</button>
    </div> );
}

export default Newsletter;