const apiURL = "http://localhost:8169/";

export const submitMail = (mail: string) => {
  fetch(apiURL + "api/sendMail", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      "email": mail,
      "subject": "cus picus",
      "text": "ayoo"
    })   
  });
};
