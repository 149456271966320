import "../../styles/animations.css"

interface Props {
    text: string,
    author: string
}

function QuoteBox(props: Props) {
  return (
    <div className="fade-in-bottom" style={{maxWidth: "50%"}}>
      <div className="quote">
      <p style={styles.quote}>"{props.text}"</p>
      <blockquote style={styles.author}>{"- " + props.author}</blockquote>
      </div>
    </div>
  );
}

const styles = {
    quote: {
        margin: 0,

        fontFamily: "serif", 
        fontSize: "3vw"
    },
    author: {
        margin: "0.8vw 0vw 0vw 3vw",
        fontSize: "1.8vw"
    }
}

export default QuoteBox;
