import "./styles/mainPage.css";

import QuoteContainer from "../components/MainPage/QuoteContainer";
import MainPageContent from "../components/MainPage/MainPageContent";
import Newsletter from "../components/MainPage/Newsletter";

function MainPage() {

  return (
    <div className="container">
      <QuoteContainer/>
      <MainPageContent/>
      <Newsletter/>
      <p>Pouze pro satirické účely/Entertainment purposes only.</p>
    </div>
  );
}

export default MainPage;
