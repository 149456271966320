import QuoteBox from "./QuoteBox";
import { useState } from "react";
import { quotes } from "../../assets/quotes";

import "../../pages/styles/mainPage.css"

function QuoteContainer() {
  const [quote, setQuote] = useState(
    quotes[Math.floor(Math.random() * quotes.length)]
  );

  return (
    <div className="quoteContainer">
      <QuoteBox text={quote.text} author={quote.author}></QuoteBox>
    </div>
  );
}

export default QuoteContainer;
