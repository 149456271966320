import "../../pages/styles/mainPage.css"

function MainPageContent() {
    return ( 
        <div className="contentContainer">
            <div className="content">
                <h1>Ever felt like giving up?</h1>
                <h2>good <br/>nows the time to fulfil your lifelong dream :3</h2>
                <p>suicide good nebo něco idk</p>
            </div>
            <div className="horizontalAlign content" style={{maxWidth: "60vw"}}>
                <img src={require("../../assets/images/bg.jpg")} style={{maxWidth:"20vw"}}/>
                <p><strong>Need a little less motivation?</strong><br/>
                then our newsletter package is perfect for you! Recieve a personalised reason to why life is agony in your email every week!</p>
            </div>
            <div className="horizontalAlign content" style={{maxWidth: "60vw"}}>
                
                <p><strong>please dont die :(</strong><br/>
                we actually love and appreciate you very much</p>
                <img src={require("../../assets/images/bg.jpg")} style={{maxWidth:"20vw"}}/>
            </div>
        </div>
     );
}

export default MainPageContent;