export interface QuoteData {
    text: string,
    author: string
}

export const quotes: QuoteData[] = [
        {
            "text": "zivot je na hovno fr",
            "author": "ja"
        },
        {
            "text": "one day, I was in a business meeting. I farted, and the fleeting gas made me realize - we are as temporary as fart",
            "author": "fish"
        },
        {
            "text": "to neni kink to je lifestyle",
            "author": "ashley"
        },
        {
            "text": "she zpracovavani my data till i porg",
            "author": "porg ict 2023"
        },
        {
            "text": "When you're feeling down, give up. It ain't gonna be better.",
            "author": "vojta"
        },
        {
            "text": "The bridge's lookin lovely, ain't it?",
            "author": "nekdo chytrej asi idk"
        },
        {
            "text": "All alone! Whether you like it or not, alone is something you'll be quite a lot!",
            "author": "Dr. Seuss (actual quote)"
        },
        {
            "text": "You're gonna die eventually, might as well speed up the process",
            "author": "ja (fr)"
        },
        {
            "text": "Pouze pro satirické účely/Entertainment purposes only.",
            "author": "my"
        },
        {
            "text": "reacte jdi už fakt do píči s těma animacema",
            "author": "uwu"
        },
    ]
